import React from 'react';
import { useLocation } from '@reach/router';
import Calendar from '../images/calendar.svg';

function EventsArchive({ events }) {
  const todaysDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];

  const DetailsText = {
    'en': 'Details',
    'pt': 'Detalhes',
  };

  return (
    <div className="events-list columns is-centered is-multiline pb-6" id="archive">
      {events.map((event, index) => (
        <>
          {todaysDate <= event.frontmatter.date ? (
            <div key={index} className="column event is-one-third">
              <h4>{event.frontmatter.title}</h4>
              <div>
                <img src={Calendar} alt="calendar icon" />
                <p>{event.frontmatter.date2} | {event.frontmatter.start_time}</p>
              </div>
              <a href={event.frontmatter.slug} className="button">{DetailsText[currentPathLanguage]}</a>
            </div>
          ) : (
            <></>
          )}
        </>
      ))}
    </div>
  );
}

export default EventsArchive;
