import * as React from 'react';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import EventsArchive from '../../components/EventsArchive';

export { default as Head } from '../../components/Head';

const Page = ({ data }) => {
  const events = data?.events?.nodes;
  return (
    <Layout>
      <div className='page-title main copy'>
        <div className="columns">
          <div className="left">
            <h1>All<br/><span>Pre-Events</span></h1>
            <div className="line-wrapper">
              <div className="h2underline"></div>
              <div className="marginline"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container main">
        <EventsArchive events={events} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    events: allMdx(filter: { fields: { slug: { glob: "/en/events/*" } } } sort: { fields: frontmatter___date }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date
          date2
          start_time
          category
          venue_name
          subcategory
          slug
        }
        excerpt
      }
    }
  }
`;
export default Page;
